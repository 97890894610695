<template>
  <v-app>
    <v-content>
      <v-layout row wrap fill-height align-center justify-center style="background: linear-gradient(to right, #314755, #26a0da);">
        <v-list-item v-if="$vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md' " class="text-left pl-10 pa-7" style="background-color:  #314755">
              <v-list-item-text >
                <b style="font-size: 50px ; color:white;">หน้ายืนยันการลบบัญชี One Box</b>
              </v-list-item-text>
          </v-list-item>
          <v-list-item v-else  class="text-left pl-10 pa-7" style="background-color:  #314755">
              <v-list-item-text >
                <b style="font-size: 20px ; color:white;">หน้ายืนยันการลบบัญชี One Box</b>
              </v-list-item-text>
          </v-list-item>
        <v-flex lg6 xs11>
        <!-- จอใหญ่ -->
          <div class="text-center" v-if="$vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md'">
            <v-card outlined style="background-color: white" class="elevation-5" >
              <br />    
              
                <v-card-text>
                <img  src="@/assets/logo_onebox-horizontal-png.png" width="25%" height="25%">
              
              </v-card-text>
              <v-card-text style="font-size: 20px" class="pl-12 pr-12">
                <b>กรุณาเตรียมโทรศัพท์ที่ต้องการขอลบบัญชีของคุณให้พร้อม</b>
                
                <!-- <v-text-field label="ชื่ิอ" outlined clearable></v-text-field> -->
                <!-- {{ filedata['filename'] }} -->
              </v-card-text>
              <v-card-text style="font-size: 15px" class="text-left pl-7"><b>ขั้นตอนที่ 1 กรอกหมายเลขโทรศัพท์ที่ต้องการขอลบบัญชี เพื่อขอ OTP</b></v-card-text>
              <!-- <v-card-text><v-card-subtitle>กรุณากรอกเบอร์โทรเพื่อยืนยัน</v-card-subtitle></v-card-text> -->
              <v-card-text style="font-size: 20px" class="pl-16 pr-16  ">
                <!-- <b>เบอร์โทร:</b> -->
                <v-text-field onkeyup="if(this.value > 9999999999) this.value = '';" type="number" prepend-icon="mdi-phone" class="pl-16 pr-16  " hide-details dense  v-model="telnumber" label="เบอร์โทรศัพท์ :" outlined clearable></v-text-field>
                <!-- {{ calculatesize(filedata['size_file'] )}} -->
              </v-card-text>
              <v-card-actions class="pl-16 pr-16 ml-16 mr-16">                 
                <v-btn block color="primary" :loading="getotpload" :disabled="gettel" depressed @click="fn_getotp()" >ขอ OTP</v-btn>
                <!-- <v-btn  class="ma-2" color="red" depressed outlined  >ยกเลิก</v-btn> -->
              </v-card-actions>

              <v-card-text style="font-size: 15px" class="text-left pl-7"><b>ขั้นตอนที่ 2 กรอกหมายเลข OTP ที่ส่งไปยังเบอร์โทรศัพท์ของคุณ</b></v-card-text>
               <v-card-text style="font-size: 13px">รหัสอ้างอิง : <b>{{this.ref_otp}}</b></v-card-text>
              <!-- <v-card-text><v-card-subtitle>กรุณากรอกเบอร์โทรเพื่อยืนยัน</v-card-subtitle></v-card-text> -->
              <v-card-text style="font-size: 20px" class="pl-16 pr-16  ">
                <!-- <b>เบอร์โทร:</b> -->
                <v-text-field class="pl-16 pr-16  " hide-details dense  v-model="otpnumber" label="หมายเลข OTP :" outlined clearable></v-text-field>
                <!-- {{ calculatesize(filedata['size_file'] )}} -->
              </v-card-text>
              <v-card-actions class="pl-16 pr-16 pb-7 ml-16 mr-16">                 
                <v-btn block color="primary"  :disabled="getotp" depressed @click="fn_confirmdelete()" >ยืนยัน</v-btn>
                <!-- <v-btn  class="ma-2" color="red" depressed outlined  >ยกเลิก</v-btn> -->
              </v-card-actions>
              
              <!-- <br /> -->
              <!-- <v-divider></v-divider> -->
              <!-- <v-card-action>
                <p class="pt-1" >
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom;"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-action> -->
            </v-card>
          </div>
 
           <!-- จอเล็ก -->
           <div class="text-center" v-else >
             <v-card outlined style="background-color: white" class="elevation-5" >
              <br />    
              
                <v-card-text class="pb-0">
                <img  src="@/assets/logo_onebox-horizontal-png.png" width="25%" height="25%">
              
              </v-card-text>
              <v-card-text style="font-size: 20px" class="pl-12 pr-12">
                <b>กรุณาเตรียมโทรศัพท์ที่ต้องการขอลบบัญชีของคุณให้พร้อม</b>
                
                <!-- <v-text-field label="ชื่ิอ" outlined clearable></v-text-field> -->
                <!-- {{ filedata['filename'] }} -->
              </v-card-text>
              <v-card-text style="font-size: 15px" class="text-left pl-7"><b>ขั้นตอนที่ 1 กรอกหมายเลขโทรศัพท์ที่ต้องการขอลบบัญชี เพื่อขอ OTP</b></v-card-text>
              <!-- <v-card-text><v-card-subtitle>กรุณากรอกเบอร์โทรเพื่อยืนยัน</v-card-subtitle></v-card-text> -->
              <v-card-text style="font-size: 20px" class="pl-10 pr-10  ">
                <!-- <b>เบอร์โทร:</b> -->
                <v-text-field onkeyup="if(this.value > 9999999999) this.value = '';"  type="number"   prepend-icon="mdi-phone"  hide-details dense  v-model="telnumber" label="เบอร์โทรศัพท์ :" outlined clearable></v-text-field>
                <!-- {{ calculatesize(filedata['size_file'] )}} -->
              </v-card-text>
              <v-card-actions class="pl-10 pr-10 ml-10 mr-10">                 
                <v-btn block color="primary" :loading="getotploading" :disabled="gettel" depressed @click="fn_getotp()" >ขอ OTP</v-btn>
                <!-- <v-btn  class="ma-2" color="red" depressed outlined  >ยกเลิก</v-btn> -->
              </v-card-actions>

              <v-card-text style="font-size: 15px" class="text-left pl-7 pb-0"><b>ขั้นตอนที่ 2 กรอกหมายเลข OTP ที่ส่งไปยังเบอร์โทรศัพท์ของคุณ</b></v-card-text>
               <v-card-text style="font-size: 13px" >รหัสอ้างอิง : <b>{{this.ref_otp}}</b></v-card-text>
              <!-- <v-card-text><v-card-subtitle>กรุณากรอกเบอร์โทรเพื่อยืนยัน</v-card-subtitle></v-card-text> -->
              <v-card-text style="font-size: 20px" class="pl-10 pr-10 pt-0">
                <!-- <b>เบอร์โทร:</b> -->
                <v-text-field hide-details dense v-model="otpnumber" label="หมายเลข OTP :" outlined clearable></v-text-field>
                <!-- {{ calculatesize(filedata['size_file'] )}} -->
              </v-card-text>
              <v-card-actions class="pl-10 pr-10 pb-7 ml-10 mr-10">                 
                <v-btn block color="primary" :loading="deleteloading"  :disabled="getotp" depressed @click="fn_confirmdelete()" >ยืนยัน</v-btn>
                <!-- <v-btn  class="ma-2" color="red" depressed outlined  >ยกเลิก</v-btn> -->
              </v-card-actions>
              
              <!-- <br /> -->
              <!-- <v-divider></v-divider> -->
              <!-- <v-card-action>
                <p class="pt-1" >
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom;"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-action> -->
            </v-card>
          </div>
          <!-- <div class="text-center" v-else>
            <v-card outlined class="elevation-2" style="background-color: #f2faff">
              <br />
              <v-card-text>
                <v-icon size="100" medium color="red">mdi-file-cancel</v-icon>
              </v-card-text>
              <v-card-text style="font-size: 20px">ไม่พบไฟล์นี้ในระบบ หรือ ไฟล์นี้ถูกปิดการแชร์</v-card-text>
              <br />
              <v-divider></v-divider>
              <v-card-action>
                <p class="pt-1" >
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom;"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-action>
            </v-card>
          </div> -->
        </v-flex>
      </v-layout>
       <!-- snackbardowload -->
    <!-- <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar color="primary" dense short flat fab>
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowload = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{namedowload}}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
            >&nbsp; {{ percentCompleted }}</v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div> -->
    <!-- <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload=false"
      :percent="percent"
      :namedowload="namedowload"
      :btsdownload="btsdownload"
    ></dialogsnackbermultidowload>
    <previewpublicfile
      :show="openpreviewpublicfile"
      :filedata="currentfile"
      :parentfolder="parentfolder"
       @closepreviewfile="openpreviewpublicfile = false"
       @openpreviewfile="openpreviewpublicfile = true"
    ></previewpublicfile> -->
    </v-content>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
// const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
// const previewpublicfile = () => import("../components/optional/dialog-previewpublic");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  data: function() {
    return {
      deleteloading:false,
      ref_otp:"",
      otpnumber:"",
      gettel:false,
      getotp:true,
      getotploading:false,
      telnumber:"",
      rules: {
          required: value => !!value || 'Required.',        
          telephonenumber: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || 'Invalid telephonenumber.'
          },
      },
      
    };
    
  },
  computed:{
   
   
  },
  components:{},
  methods: {
    validatePhoneNumber(input_str) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(input_str);
},
   async fn_getotp(){
      //this.getotpload = false;
      if(this.telnumber === ""){
        Toast.fire({
              icon: "error",
              title: "กรุณากรอกเบอร์โทรศัพท์",
            });
        
      }else{
        if(this.validatePhoneNumber(this.telnumber)){    
          this.getotploading = true;
          var payload = {
            tel:this.telnumber
          }
        let auth = await gbfGenerate.generateToken();
        await this.axios
        .post(process.env.VUE_APP_GET_OTP + "/api/generate_otp_to_delete_data", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
         console.log(response);
         if(response.data.status == "OK"){
           this.getotploading = false;
            this.getotp = false
            this.gettel = true
            this.ref_otp = response.data.ref_otp
         }else{
            this.getotploading = false;
            Toast.fire({
            icon: "error",
            title: "error",
          });
         }
        })
        .catch((error) => {
          this.getotploading = false;
          this.telnumber = ""
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
      }else{
         this.getotploading = false;
         this.telnumber = ""
        Toast.fire({
              icon: "error",
              title: "หมายเลขโทรศัพท์ไม่ถูกต้อง",
            });
      }
      }
    },
   async fn_confirmdelete(){
      if(this.otpnumber === ""){
          Toast.fire({
              icon: "error",
              title: "กรุณากรอกเบอร์โทรศัพท์",
            });
      }else{
      this.deleteloading= true;
       var payload = {
            otp: this.otpnumber,
            ref_otp: this.ref_otp,
            tel: this.telnumber
          }
        let auth = await gbfGenerate.generateToken(); 
        await this.axios
        .post(process.env.VUE_APP_GET_OTP + "/api/validate_otp_to_delete_data", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
         console.log(response);
         if(response.data.status == "OK"){
           this.deleteloading = false;
            Swal.fire({
                title: "ส่งคำขอลบบัญชีของเบอร์โทรศัพท์ "+this.telnumber+" เรียบร้อยแล้ว",
                text:"ระบบได้รับเรื่องการขอลบบัญชีของคุณเรียบร้อยแล้ว อยู่ในระหว่างการดำเนินการ คุณจะได้รับ SMS ตอบกลับภายใน 2 วันทำการ",
                 icon: "success",
                })
           this.otpnumber = "";
           this.ref_otp = "";
           this.telnumber = "";
            this.getotp = true
            this.gettel = false
         }else{
           this.deleteloading = false;
           this.otpnumber = "";
           Toast.fire({
              icon: "error",
              title: "ส่งคำขอลบบัญชีไม่สำเร็จ",
            });
         }
        })
        .catch((error) => {
          this.deleteloading = false;
          this.otpnumber = "";
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
      }
    },



    fn_confirm(){
      //console.log("tel",Number.isInteger(this.telnumber));
      this.getotpload = true;
      if(this.telnumber === ""){
        Toast.fire({
              icon: "error",
              title: "กรุณากรอกเบอร์โทรศัพท์",
            });
        this.getotpload = false;
      }else{
         
          var payload = {
            tel:this.telnumber
          }
        this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/generate_otp_to_delete_data", payload)
        .then((response) => {
         console.log(response);
         if(response.data.status == "OK"){

         }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
          
        Swal.fire({
            title: 'ยืนยัน OTP',
            text:"รหัสอ้างอิง :"+response.data.refotp,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
              

      //         console.log("login",login);
      //           return fetch(`//api.github.com/users/${login}`)
      //           .then(response => {
      //             console.log(response);
      //               if (!response.ok) {
      //               throw new Error(response.statusText)
      //               }
      //               return response.json()
      //           })
      //           .catch(error => {
      //               Swal.showValidationMessage(
      //               `Request failed: ${error}`
      //               )
      //  })
                },
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              console.log(result);
            if (result.isConfirmed) {
                Swal.fire({
                title: "ส่งคำขอลบบัญชีของเบอร์โทรศัพท์"+" "+this.telnumber+" "+"เรียบร้อยแล้ว",
                text:"ระบบได้รับเรื่องการขอลบบัญชีของคุณเรียบร้อยแล้ว อยู่ในระหว่างการดำเนินการ คุณจะได้รับ SMS ตอบกลับภายใน 2 วันทำการ",
                 icon: "success",
                })
            }
            })
      }
    },
    gotohomepage(){
        this.$router.push('/')
    }
  },
  mounted() {
    // if (this.$route.query.id === "" || !this.$route.query.id) {
    //   this.loading = false;
    // } else {
    //   this.getfiledetail();
      
    // }
    sessionStorage.removeItem("onechat_token");
    sessionStorage.removeItem("onechat_plugin_enable");
    //console.log("onechat_token",sessionStorage.getItem("onechat_token"));
  }
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>